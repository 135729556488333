<mat-card class="container">
        <mat-card-title>Planos</mat-card-title>
        <div class="app-actions" fxLayout="column">
                <div fxLayoutAlign="end center">
                        <a mat-raised-button color="accent" [routerLink]="['/planos/criar']">Novo</a>
                </div>
        </div>
        <div fxLayout="column">
                <mat-progress-bar mode="query" *ngIf="datasource?.isLoading"></mat-progress-bar>

                <table mat-table [dataSource]="datasource">
                        <ng-container matColumnDef="planName">
                                <th mat-header-cell *matHeaderCellDef> Plano </th>
                                <td mat-cell *matCellDef="let plan"> {{ plan.name }} </td>
                        </ng-container>

                        <ng-container matColumnDef="patientCount">
                                <th mat-header-cell *matHeaderCellDef> Quantidade de Pacientes </th>
                                <td mat-cell *matCellDef="let plan"> 50 </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                        <tr class="actionable" mat-row *matRowDef="let rowData; columns: columnsToDisplay"
                                (click)="rowClicked(rowData)">
                        </tr>
                </table>
                <mat-paginator [length]="datasource?.count" [pageIndex]="0" [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
        </div>
</mat-card>