<mat-card class="container">
    <mat-card-title>Informações</mat-card-title>
    <div fxLayoutAlign="end center" fxLayout="row" *ngIf="!patientId">
        <mat-slide-toggle #continuousMode>Modo contínuo</mat-slide-toggle>
    </div>
    <form [formGroup]="patientForm" (submit)="onSubmit()" appAutoFocus [appLoadingOverlay]="isLoading">
        <input type="hidden" formControlName="id">
        <div fxLayout="row">
            <mat-form-field fxFlex="50">
                <input matInput placeholder="Nome" formControlName="name" maxlength="30">
                <mat-error>{{ patientForm.getControlMessages('name') }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <input matInput placeholder="Sobrenome" formControlName="last_name" maxlength="30">
                <mat-error>{{ patientForm.getControlMessages('last_name') }}</mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex="50">
                <input matInput placeholder="Telefone" formControlName="phone" mask="(00) 00009-0000">
                <mat-error>{{ patientForm.getControlMessages('phone') }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <mat-select placeholder="Sexo" formControlName="sex">
                    <mat-option [value]="'M'">Masculino</mat-option>
                    <mat-option [value]="'F'">Feminino</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex="50">
                <mat-select placeholder="Plano" formControlName="dental_plan" [compareWith]="defaultMatcher">
                    <mat-option *ngFor="let plan of dentalPlans" [value]="plan">{{ plan.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="50">
                <mat-select placeholder="Clinica" formControlName="clinic" [compareWith]="defaultMatcher">
                    <mat-option *ngFor="let clinic of clinics" [value]="clinic">{{ clinic.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex="50">
                <input matInput placeholder="Número da carteirinha" formControlName="dental_plan_card_number" maxlength="50">
                <mat-error>{{ patientForm.getControlMessages('dental_plan_card_number') }}</mat-error>
            </mat-form-field>
        </div>
        <div class="app-actions" fxLayoutGap="10px" fxLayoutAlign="end center">
            <button type="button" mat-raised-button color="warn" *ngIf="patientForm.controls.id.value != ''"
                (click)="onDelete()" [appBtnLoader]="isSubmitting">
                <span *ngIf="!isSubmitting">Apagar</span>
            </button>
            <button mat-raised-button color="accent" [disabled]="!patientForm.valid" [appBtnLoader]="isSubmitting">
                <span *ngIf="!isSubmitting">Salvar</span>
            </button>
        </div>
    </form>
</mat-card>

<mat-card class="container" [hidden]="!patientId">
    <mat-card-title>Agendamentos</mat-card-title>
    <mat-progress-bar mode="query" *ngIf="datasource?.isLoading"></mat-progress-bar>

    <table mat-table [dataSource]="datasource">

        <ng-container matColumnDef="scheduleDate">
            <th mat-header-cell *matHeaderCellDef> Data </th>
            <td mat-cell *matCellDef="let schedule"> {{schedule.date | date:'dd/MM/yy H:mm' }} </td>
        </ng-container>

        <ng-container matColumnDef="scheduleStatus">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let schedule">
                <app-schedule-status [status]="schedule.status"></app-schedule-status>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row class="actionable" *matRowDef="let rowData; columns: columnsToDisplay"
            (click)="viewSchedule(rowData)">

        </tr>
    </table>
    <mat-paginator [length]="datasource?.count" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

</mat-card>