<div class="app-action">
    <button mat-fab fxShow="false" fxShow.lt-lg>
        <mat-icon>add</mat-icon>
    </button>
</div>
<mat-card class="container">
    <mat-card-title>Clinicas</mat-card-title>
    <div class="app-actions" fxLayoutAlign="end center">
        <a mat-raised-button color="accent" [routerLink]="['/clinicas', 'criar']">Novo</a>
    </div>
    <div fxLayout="column">
        <mat-progress-bar [mode]="'query'" *ngIf="isLoading"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Nome </th>
                <td mat-cell *matCellDef="let clinic"> {{ clinic.name }}</td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Ação </th>
                <td mat-cell *matCellDef="let clinic">
                    <button mat-icon-button (click)="view(clinic)">
                        <mat-icon>launch</mat-icon>
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [length]="count" [pageIndex]="0" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
    </div>
</mat-card>